import React, { useState } from 'react';
import { Shield, Lock, User, Clock, Zap, ArrowRight, AlertCircle } from 'lucide-react';
import { api } from '../../services/api';
import { SpinningRing } from '../SpinningRing';

interface FormData {
    rut: string;
    password: string;
}

interface SIICredentialsSetupProps {
    onComplete: () => Promise<void>;
}

export function SIICredentialsSetup({ onComplete }: SIICredentialsSetupProps) {
    const [formData, setFormData] = useState<FormData>({
        rut: '',
        password: '',
    });
    const userName = localStorage.getItem('userName');
    const companyRut = localStorage.getItem('companyRut');
    const companyName = localStorage.getItem('companyName');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const calculateVerifierDigit = (rutBody: string): string => {
        const reversed = rutBody.split('').reverse();
        let sum = 0;
        let multiplier = 2;

        for (const digit of reversed) {
            sum += parseInt(digit) * multiplier;
            multiplier = multiplier === 7 ? 2 : multiplier + 1;
        }

        const remainder = sum % 11;
        const verifierDigit = 11 - remainder;

        if (verifierDigit === 11) return '0';
        if (verifierDigit === 10) return 'K';
        return verifierDigit.toString();
    };

    const formatRut = (value: string) => {
        const cleaned = value.replace(/[^0-9Kk]/g, '').toUpperCase();
        if (!cleaned) return '';
        if (cleaned.length === 1) return cleaned;

        let body = cleaned;
        let dv = '';

        // Si el valor incluye dígito verificador
        if (cleaned.length > 1) {
            body = cleaned.slice(0, -1);
            dv = cleaned.slice(-1);
        }

        // Formatear el cuerpo con puntos
        const reversedBody = body.split('').reverse().join('');
        const formattedReversedBody = reversedBody.match(/.{1,3}/g)?.join('.') || '';
        const formattedBody = formattedReversedBody.split('').reverse().join('');

        // Si no hay DV, calcularlo
        if (!dv && body.length > 0) {
            dv = calculateVerifierDigit(body);
        }

        return formattedBody + (dv ? '-' + dv : '');
    };

    const handleRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedRut = formatRut(e.target.value);
        setFormData(prev => ({ ...prev, rut: formattedRut }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const masterEntityId = localStorage.getItem('temporalMasterEntityId');
            if (!masterEntityId) {
                throw new Error('No se encontró la entidad seleccionada');
            }

            const response = await api.post(`/master-entities/${masterEntityId}/credentials/`, {
                user: formData.rut.replace(/\./g, ''),
                password: formData.password,
                is_onboarding: true
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Error al configurar las credenciales');
            }

            localStorage.removeItem('temporalMasterEntityId');

            try {
                await onComplete();
            } catch (error) {
                console.error('Error durante el onboarding:', error);
                setError('Hubo un error al cargar los documentos. Por favor, intenta nuevamente.');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error al configurar credenciales:', error);
            setIsLoading(false);
            if (error instanceof Error && error.message.includes('credentials')) {
                setError('Credenciales inválidas. Por favor, verifica tus datos.');
            } else {
                setError('Hubo un error al configurar las credenciales. Por favor, intenta nuevamente.');
            }
        }
    };

    return (
        <div className="max-w-4xl mx-auto mt-1">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-8">
                    <div className="flex items-center justify-center mb-8">
                        <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
                            <Shield className="h-8 w-8 text-green-600" />
                        </div>
                    </div>

                    <div className="text-center mb-8">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">
                            ¡Un último paso para acelerar tu facturación!
                        </h2>
                        <p className="text-lg text-gray-600 mb-4">
                            ¡Hola {userName}!
                        </p>
                    </div>

                    <div className="bg-gray-50 p-4 rounded-lg mb-8 text-center">
                        <p className="font-medium text-gray-800">Empresa seleccionada:</p>
                        <p className="text-gray-600">{companyName}</p>
                        <p className="text-sm text-gray-500">RUT: {companyRut}</p>
                    </div>

                    <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-6 mb-8">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                RUT Personal del Encargado de Facturar
                            </label>
                            <p className="text-sm text-gray-500 mb-2">
                                Ingresa el RUT de la persona natural encargada de facturar (debe ser menor a 50 millones y con permisos para facturar)
                            </p>
                            <div className="relative">
                                <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                                <input
                                    type="text"
                                    value={formData.rut}
                                    onChange={handleRutChange}
                                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="12.345.678-9"
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Contraseña SII
                            </label>
                            <div className="relative">
                                <Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                                <input
                                    type="password"
                                    value={formData.password}
                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    placeholder="Contraseña del SII"
                                    required
                                />
                            </div>
                            <div className="mt-2 text-sm text-gray-500">
                                <div className="flex items-center space-x-2">
                                    <Shield className="h-4 w-4 text-green-500" />
                                    <span>Tus credenciales se almacenan de forma segura y encriptada</span>
                                </div>
                                <div className="flex items-center space-x-2 mt-1">
                                    <Lock className="h-4 w-4 text-green-500" />
                                    <span>Solo se utilizarán para listar y emitir facturas a través del SII</span>
                                </div>
                            </div>
                        </div>

                        {error && (
                            <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <AlertCircle className="h-5 w-5 text-red-400" />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm text-red-700">
                                            {error}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex justify-end">
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="w-full flex items-center justify-center space-x-2 bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition disabled:bg-green-400"
                            >
                                {isLoading ? (
                                    <>
                                        <SpinningRing size="sm" thickness="normal" className="text-white" />
                                        <span>Configurando credenciales...</span>
                                    </>
                                ) : (
                                    <>
                                        <span>Comenzar a Facturar</span>
                                        <ArrowRight className="h-5 w-5" />
                                    </>
                                )}
                            </button>
                        </div>
                    </form>

                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="bg-gradient-to-br from-red-50 to-white p-6 rounded-lg border border-red-100">
                            <h3 className="text-lg font-semibold text-red-700 mb-4 flex items-center">
                                <Clock className="h-5 w-5 mr-2" />
                                Sin Automatización
                            </h3>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-center">
                                    <ArrowRight className="h-4 w-4 mr-2 text-red-400" />
                                    <span>Horas perdidas en facturación manual</span>
                                </li>
                                <li className="flex items-center">
                                    <ArrowRight className="h-4 w-4 mr-2 text-red-400" />
                                    <span>Mayor riesgo de errores humanos</span>
                                </li>
                                <li className="flex items-center">
                                    <ArrowRight className="h-4 w-4 mr-2 text-red-400" />
                                    <span>Retrasos en el flujo de caja</span>
                                </li>
                            </ul>
                        </div>

                        <div className="bg-gradient-to-br from-green-50 to-white p-6 rounded-lg border border-green-100">
                            <h3 className="text-lg font-semibold text-green-700 mb-4 flex items-center">
                                <Zap className="h-5 w-5 mr-2" />
                                Con Pana
                            </h3>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-center">
                                    <ArrowRight className="h-4 w-4 mr-2 text-green-400" />
                                    <span>Facturación automática en segundos</span>
                                </li>
                                <li className="flex items-center">
                                    <ArrowRight className="h-4 w-4 mr-2 text-green-400" />
                                    <span>Validación automática de datos</span>
                                </li>
                                <li className="flex items-center">
                                    <ArrowRight className="h-4 w-4 mr-2 text-green-400" />
                                    <span>Flujo de caja más rápido</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}