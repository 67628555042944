import React, { useState, useRef, useEffect } from 'react';
import { Layout } from '../components/platform/layout/Layout';
import { ChatBubble } from '../components/chat/ChatBubble';
import { ChatInput } from '../components/chat/ChatInput';
import { PreviewModal } from '../components/chat/PreviewModal';
import { InvoicePreview } from '../components/chat/InvoicePreview';
import { Invoice } from '../types/invoice';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { useEntity } from '../context/EntityContext';
import { api } from '../services/api';

interface Message {
    role: 'user' | 'assistant';
    content: string;
    isTyping?: boolean;
    isInvoice?: boolean;
    isBulkInvoice?: boolean;
    pdfData?: string;
}

export function YourPanaInvoicer() {
    const navigate = useNavigate();
    const { state: { selectedEntity } } = useEntity();
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const welcomeRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [previewInvoice, setPreviewInvoice] = useState<Invoice | null>(null);
    const [previewBulkInvoices, setPreviewBulkInvoices] = useState<Invoice[] | null>(null);
    const [showTraditionalMenu, setShowTraditionalMenu] = useState(false);
    const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [invoiceData, setInvoiceData] = useState<any>({});

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, []);

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, [messages, isProcessing]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.traditional-menu')) {
                setShowTraditionalMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSendMessage = async () => {
        if (!input.trim()) return;

        if (!selectedEntity?.id) {
            setMessages((prev) => [
                ...prev,
                { role: 'assistant', content: 'Por favor, selecciona una empresa antes de continuar.' }
            ]);
            return;
        }

        const userMessage: Message = { role: 'user', content: input };
        setMessages((prev) => [...prev, userMessage]);
        setInput('');
        setIsProcessing(true);

        const typingMessage: Message = { role: 'assistant', content: '', isTyping: true };
        setMessages((prev) => [...prev, typingMessage]);

        try {
            const formData = new FormData();
            formData.append('message', input);

            // Convert current messages to the conversation format
            const conversationHistory = messages.map(msg => ({
                role: msg.role,
                content: msg.content
            }));

            // Add current user message to history
            conversationHistory.push({
                role: 'user',
                content: input
            });

            formData.append('conversation', JSON.stringify(conversationHistory));
            formData.append('invoice_data', JSON.stringify(invoiceData));
            formData.append('entity_id', selectedEntity.id);

            const response = await api.post('/chat_v2/', formData);
            const data = await response.json();

            // Update invoice data if present
            if (data.invoice_data) {
                setInvoiceData(data.invoice_data);
            }

            // Get the latest assistant message from conversation
            const latestMessage = data.conversation[data.conversation.length - 1];

            const newMessage: Message = {
                role: 'assistant',
                content: latestMessage.content,
                isInvoice: data.parsed_intent === 'invoice',
                isBulkInvoice: data.parsed_intent === 'bulk_invoice',
            };

            setMessages((prev) => [...prev.slice(0, -1), newMessage]);
            setSelectedInvoices([]);
        } catch (error) {
            console.error('Error al conectar con el backend:', error);
            setMessages((prev) => [
                ...prev.slice(0, -1),
                { role: 'assistant', content: 'Error al procesar tu solicitud. Intenta de nuevo.' },
            ]);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleFileUpload = async (file: File) => {
        if (!selectedEntity?.id) {
            setMessages((prev) => [
                ...prev,
                { role: 'assistant', content: 'Por favor, selecciona una empresa antes de continuar.' }
            ]);
            return;
        }

        const userMessage: Message = { role: 'user', content: `Archivo subido: ${file.name}` };
        setMessages((prev) => [...prev, userMessage]);
        setIsProcessing(true);

        const typingMessage: Message = { role: 'assistant', content: '', isTyping: true };
        setMessages((prev) => [...prev, typingMessage]);

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('message', `Analiza este archivo: ${file.name}`);

            // Convert current messages to conversation format
            const conversationHistory = messages.map(msg => ({
                role: msg.role,
                content: msg.content
            }));

            // Add current file upload message to history
            conversationHistory.push({
                role: 'user',
                content: `Archivo subido: ${file.name}`
            });

            formData.append('conversation', JSON.stringify(conversationHistory));
            formData.append('invoice_data', JSON.stringify(invoiceData));
            formData.append('entity_id', selectedEntity.id);

            const response = await api.post('/chat_v2/', formData);
            const data = await response.json();

            // Update invoice data if present
            if (data.invoice_data) {
                setInvoiceData(data.invoice_data);
            }

            // Get the latest assistant message from conversation
            const latestMessage = data.conversation[data.conversation.length - 1];

            const newMessage: Message = {
                role: 'assistant',
                content: latestMessage.content,
                isInvoice: data.parsed_intent === 'invoice',
                isBulkInvoice: data.parsed_intent === 'bulk_invoice',
            };

            setMessages((prev) => [...prev.slice(0, -1), newMessage]);
            setSelectedInvoices([]);
        } catch (error) {
            console.error('Error al procesar archivo:', error);
            setMessages((prev) => [
                ...prev.slice(0, -1),
                { role: 'assistant', content: 'Error al procesar el archivo. Intenta de nuevo.' },
            ]);
        } finally {
            setIsProcessing(false);
        }
    };

    const handlePreviewToggle = (invoiceId: string) => {
        setSelectedInvoices((prev) =>
            prev.includes(invoiceId)
                ? prev.filter((id) => id !== invoiceId)
                : [...prev, invoiceId]
        );
    };

    const handlePreview = (content: string, isBulk: boolean) => {
        try {
            if (isBulk) {
                const invoices = JSON.parse(content) as Invoice[];
                setPreviewBulkInvoices(invoices);
            } else {
                const invoice = JSON.parse(content) as Invoice;
                setPreviewInvoice(invoice);
            }
        } catch (e) {
            console.error('Error parsing invoice:', e);
        }
    };

    return (
        <Layout>
            <div className="absolute top-24 right-6 z-50">
                <div className="relative traditional-menu group">
                    <button
                        className="w-12 h-12 bg-white/80 backdrop-blur-sm rounded-full shadow-sm hover:shadow-md transition-all duration-300 flex items-center justify-center group-hover:w-[420px] overflow-hidden"
                        onClick={() => setShowTraditionalMenu(!showTraditionalMenu)}
                    >
                        <Plus className="h-5 w-5 text-gray-400 group-hover:opacity-0 transition-opacity absolute" />
                        <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-100 text-gray-600 whitespace-nowrap">
                            ¿No quieres usar el chat? Emite de forma tradicional
                        </span>
                    </button>

                    {showTraditionalMenu && (
                        <div className="absolute right-0 mt-2 w-48 bg-white/95 backdrop-blur-sm rounded-lg shadow-lg border border-gray-100 py-1 z-50">
                            <button
                                onClick={() => navigate('/platform/invoice-form')}
                                className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-50 transition-colors"
                            >
                                Emitir una Factura
                            </button>
                            <button
                                onClick={() => navigate('/platform/bulk-invoice-excel')}
                                className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-50 transition-colors"
                            >
                                Emisión Masiva
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="h-[calc(100vh-5rem)] flex flex-col bg-gray-50 relative">
                {messages.length === 0 && (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div ref={welcomeRef} className="text-center max-w-2xl mx-auto px-4">
                            <h1 className="text-4xl font-bold text-gray-900 mb-6">¡Hola! Soy Tu Pana 👋</h1>
                            <p className="text-xl text-gray-600 mb-8">
                                Estoy aquí para ayudarte con tus facturas. Puedes preguntarme sobre facturación individual,
                                masiva o subir tus archivos para procesarlos.
                            </p>
                            <div className="text-sm text-gray-500 mb-6">Escribe tu consulta para comenzar</div>
                            <div className="bg-white rounded-lg p-6 shadow-lg mb-8">
                                <p className="text-gray-700 mb-4">Algunas cosas que puedes preguntarme:</p>
                                <ul className="text-left space-y-3 text-gray-600">
                                    <li>• "Necesito emitir una factura"</li>
                                    <li>• "¿Cómo hago una facturación masiva?"</li>
                                    <li>• "Quiero subir mi archivo Excel para facturar"</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex-1 overflow-y-auto px-4 py-6">
                    <div className="max-w-3xl mx-auto">
                        {messages.map((msg, index) => (
                            <div key={index} className={`mb-4 ${msg.role === 'user' ? 'text-right' : 'text-left'}`}>
                                <ChatBubble
                                    role={msg.role}
                                    content={msg.content}
                                    isTyping={msg.isTyping}
                                    isInvoice={msg.isInvoice}
                                    isBulkInvoice={msg.isBulkInvoice}
                                    onPreview={() => handlePreview(msg.content, !!msg.isBulkInvoice)}
                                />
                                {(msg.isInvoice || msg.isBulkInvoice) && (
                                    <div className="mt-2">
                                        {msg.isBulkInvoice ? (
                                            <div className="bg-gray-100 p-4 rounded-md">
                                                <p className="text-gray-700 mb-2">Selecciona las facturas para previsualizar:</p>
                                                {(() => {
                                                    try {
                                                        const invoices = JSON.parse(msg.content);
                                                        return invoices.map((invoice: any, idx: number) => (
                                                            <div key={idx} className="flex items-center mb-2">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedInvoices.includes(idx.toString())}
                                                                    onChange={() => handlePreviewToggle(idx.toString())}
                                                                    className="mr-2"
                                                                />
                                                                <span className="text-gray-600">
                                                                    Factura para {invoice.document_receiver.rut} - Total: {invoice.document_total.total_amount}
                                                                </span>
                                                            </div>
                                                        ));
                                                    } catch (e) {
                                                        console.error('Error parsing invoice list:', e);
                                                        return <div className="text-red-500">Error al procesar las facturas</div>;
                                                    }
                                                })()}
                                                {selectedInvoices.length > 0 && (
                                                    <div className="mt-4">
                                                        {(() => {
                                                            try {
                                                                const invoices = JSON.parse(msg.content);
                                                                return invoices
                                                                    .filter((_: any, idx: number) => selectedInvoices.includes(idx.toString()))
                                                                    .map((invoice: any, idx: number) => (
                                                                        <iframe
                                                                            key={idx}
                                                                            src={invoice.pdf_data || "data:application/pdf;base64,JVBERi0xLjQK...=="}
                                                                            width="100%"
                                                                            height="300px"
                                                                            title={`Factura ${invoice.document_receiver.rut}`}
                                                                            className="mt-2 border border-gray-300 rounded-md"
                                                                        />
                                                                    ));
                                                            } catch (e) {
                                                                console.error('Error parsing selected invoices:', e);
                                                                return <div className="text-red-500">Error al mostrar las facturas seleccionadas</div>;
                                                            }
                                                        })()}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            (() => {
                                                try {
                                                    const invoice = JSON.parse(msg.content);
                                                    return invoice.pdf_data && (
                                                        <iframe
                                                            src={invoice.pdf_data}
                                                            width="100%"
                                                            height="300px"
                                                            title="Factura PDF"
                                                            className="mt-2 border border-gray-300 rounded-md"
                                                        />
                                                    );
                                                } catch (e) {
                                                    console.error('Error parsing invoice:', e);
                                                    return <div className="text-red-500">Error al mostrar la factura</div>;
                                                }
                                            })()
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                </div>

                {/* Invoice Data Preview */}
                <div className="fixed bottom-24 left-6 w-80 z-40">
                    {invoiceData && Object.keys(invoiceData).length > 0 && invoiceData.rut_receptor && (
                        <InvoicePreview
                            data={invoiceData}
                            onPreview={() => {
                                try {
                                    const invoice = {
                                        ...invoiceData,
                                        id: 'preview',
                                        cliente: invoiceData.razon_social || invoiceData.rut_receptor,
                                        fecha: invoiceData.fecha_emision,
                                        items: invoiceData.productos || []
                                    };
                                    setPreviewInvoice(invoice);
                                } catch (e) {
                                    console.error('Error previewing invoice:', e);
                                }
                            }}
                            onEmit={async () => {
                                setIsProcessing(true);
                                try {
                                    const response = await api.post(`/master-entities/${selectedEntity?.id}/documents/`, {
                                        ...invoiceData,
                                        type: 'invoice'
                                    });

                                    if (!response.ok) {
                                        throw new Error('Error al emitir la factura');
                                    }

                                    const data = await response.json();
                                    setMessages(prev => [
                                        ...prev,
                                        {
                                            role: 'assistant',
                                            content: JSON.stringify(data),
                                            isInvoice: true
                                        }
                                    ]);
                                    setInvoiceData({});
                                } catch (error) {
                                    console.error('Error emitting invoice:', error);
                                    setMessages(prev => [
                                        ...prev,
                                        {
                                            role: 'assistant',
                                            content: 'Error al emitir la factura. Por favor, intenta nuevamente.'
                                        }
                                    ]);
                                } finally {
                                    setIsProcessing(false);
                                }
                            }}
                        />
                    )}
                </div>

                <div className="border-t border-gray-200 bg-white py-6">
                    <div className="max-w-3xl mx-auto px-4">
                        <ChatInput
                            value={input}
                            onChange={setInput}
                            onSend={handleSendMessage}
                            onFileUpload={handleFileUpload}
                            isProcessing={isProcessing}
                            inputRef={inputRef}
                        />
                    </div>
                </div>

                <PreviewModal
                    invoice={previewInvoice}
                    invoices={previewBulkInvoices}
                    onClose={() => {
                        setPreviewInvoice(null);
                        setPreviewBulkInvoices(null);
                    }}
                />
            </div>
        </Layout>
    );
}