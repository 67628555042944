import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CircularLoader } from '../components/CircularLoader';
import { HandshakeIcon, Lock } from 'lucide-react';

export function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const refreshAccessToken = async (refreshToken: string) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/auth/token/refresh/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (!response.ok) {
        console.error('Error refreshing token:', await response.text());
        throw new Error('No se pudo renovar el token');
      }

      const data = await response.json();
      localStorage.setItem('accessToken', data.access);
      return data.access;
    } catch (_error) {
      throw new Error('Error al renovar el token');
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (!accessToken || !refreshToken) {
        setLoading(false);
        return;
      }

      try {
        // Si ya tiene tokens válidos, solo verificamos y navegamos
        const profileResponse = await fetch(`${import.meta.env.VITE_API_URL}/api/auth/profile/`, {
          headers: { 'Authorization': `Bearer ${accessToken}` },
        });

        if (profileResponse.ok) {
          // Si está autenticado, simplemente redirigimos
          navigate('/platform');
          return;
        }

        // Si el token expiró, intentamos renovarlo
        const newAccessToken = await refreshAccessToken(refreshToken);
        if (newAccessToken) {
          navigate('/platform');
          return;
        }
      } catch (err) {
        console.error('Error en checkAuth:', err);
      }

      // Solo si no pudimos autenticar, mostramos el login
      setLoading(false);
    };

    checkAuth();
  }, [navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    const form = e.target as HTMLFormElement;
    const email = form.elements.namedItem('email') as HTMLInputElement;
    const password = form.elements.namedItem('password') as HTMLInputElement;

    try {
      const loginResponse = await fetch(`${import.meta.env.VITE_API_URL}/api/auth/login/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email.value,
          password: password.value,
        }),
      });

      if (!loginResponse.ok) {
        throw new Error('Credenciales incorrectas');
      }

      const loginData = await loginResponse.json();
      localStorage.setItem('accessToken', loginData.access);
      localStorage.setItem('refreshToken', loginData.refresh);

      const profileResponse = await fetch(`${import.meta.env.VITE_API_URL}/api/auth/profile/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${loginData.access}`,
        },
      });

      if (!profileResponse.ok) {
        throw new Error('Error al obtener el perfil');
      }

      const profileData = await profileResponse.json();
      const entities = profileData.master_entities || [];

      if (entities.length === 0) {
        throw new Error('No hay empresas asociadas a esta cuenta');
      }

      localStorage.setItem('masterEntities', JSON.stringify(entities));
      localStorage.setItem('selectedEntityId', entities[0].id);
      localStorage.setItem('selectedEntityName', entities[0].name);

      navigate('/platform');
    } catch (error: unknown) {
      setError(error instanceof Error ? error.message : 'Error al iniciar sesión');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <CircularLoader size="lg" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-white flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
        <div className="flex justify-center mb-8">
          <div className="flex items-center space-x-2">
            <HandshakeIcon className="h-10 w-10 text-green-600" />
            <span className="text-3xl font-bold">
              Tu Pana<span className="text-green-600">.ai</span>
            </span>
          </div>
        </div>

        <h2 className="text-2xl font-bold text-center mb-8">Iniciar Sesión</h2>

        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Correo Electrónico
            </label>
            <input
              type="email"
              name="email"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Contraseña
            </label>
            <input
              type="password"
              name="password"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>

          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}

          <button
            type="submit"
            className="w-full bg-green-600 text-white py-2 rounded-md hover:bg-green-700 transition flex items-center justify-center space-x-2"
          >
            <Lock className="h-5 w-5" />
            <span>Ingresar</span>
          </button>
        </form>

        <p className="mt-4 text-sm text-center text-gray-600">
          ¿No tienes una cuenta?{' '}
          <Link to="/signup" className="text-green-600 hover:text-green-700">
            Regístrate aquí
          </Link>
        </p>
      </div>
    </div>
  );
}
