import React from 'react';
import { FileText, Building2, MapPin, Briefcase, Eye, Send } from 'lucide-react';

interface InvoiceData {
    emisor_id?: number;
    rut_receptor?: string;
    razon_social?: string | null;
    productos?: Array<{
        item_name: string;
        quantity: number;
        unit_price: number;
        item_total: number;
    }>;
    direccion?: string | null;
    actividad?: string | null;
    fecha_emision?: string;
    contacto?: string | null;
}

interface InvoicePreviewProps {
    data: InvoiceData;
    onPreview?: () => void;
    onEmit?: () => void;
}

export function InvoicePreview({ data, onPreview, onEmit }: InvoicePreviewProps) {
    if (!data || !data.rut_receptor) return null;

    return (
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-4 bg-gradient-to-br from-green-50 to-white border-b border-gray-200">
                <div className="flex items-center space-x-2 text-green-600">
                    <FileText className="h-5 w-5" />
                    <h3 className="font-medium">Vista Previa de Factura</h3>
                </div>
            </div>

            <div className="p-4 space-y-4">
                {/* Datos del Receptor */}
                <div>
                    <div className="flex items-center space-x-2 mb-2 text-gray-600">
                        <Building2 className="h-4 w-4" />
                        <h4 className="text-sm font-medium">Datos del Cliente</h4>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-3 space-y-2">
                        <div>
                            <span className="text-sm text-gray-500">RUT:</span>
                            <span className="ml-2 font-medium">{data.rut_receptor}</span>
                        </div>
                        {data.razon_social && (
                            <div>
                                <span className="text-sm text-gray-500">Razón Social:</span>
                                <span className="ml-2">{data.razon_social}</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Dirección */}
                {data.direccion && (
                    <div>
                        <div className="flex items-center space-x-2 mb-2 text-gray-600">
                            <MapPin className="h-4 w-4" />
                            <h4 className="text-sm font-medium">Dirección</h4>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-3">
                            <span>{data.direccion}</span>
                        </div>
                    </div>
                )}

                {/* Actividad Económica */}
                {data.actividad && (
                    <div>
                        <div className="flex items-center space-x-2 mb-2 text-gray-600">
                            <Briefcase className="h-4 w-4" />
                            <h4 className="text-sm font-medium">Actividad Económica</h4>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-3">
                            <span>{data.actividad}</span>
                        </div>
                    </div>
                )}

                {/* Productos */}
                {data.productos && data.productos.length > 0 && (
                    <div>
                        <div className="flex items-center justify-between mb-2">
                            <h4 className="text-sm font-medium text-gray-600">Detalle</h4>
                        </div>
                        <div className="overflow-hidden rounded-lg border border-gray-200">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-3 py-2 text-left text-xs font-medium text-gray-500">Descripción</th>
                                        <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">Cant.</th>
                                        <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">Precio</th>
                                        <th className="px-3 py-2 text-right text-xs font-medium text-gray-500">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {data.productos.map((item, index) => (
                                        <tr key={index}>
                                            <td className="px-3 py-2 text-sm">{item.item_name}</td>
                                            <td className="px-3 py-2 text-sm text-right">{item.quantity}</td>
                                            <td className="px-3 py-2 text-sm text-right">
                                                ${typeof item.unit_price === 'number' ? item.unit_price.toLocaleString('es-CL') : '0'}
                                            </td>
                                            <td className="px-3 py-2 text-sm text-right">
                                                ${typeof item.item_total === 'number' ? item.item_total.toLocaleString('es-CL') : '0'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            <div className="p-3 bg-gray-50 border-t border-gray-200 flex justify-end space-x-3">
                <button
                    onClick={onPreview}
                    className="flex items-center space-x-1.5 px-4 py-2 text-gray-600 hover:text-gray-800 rounded-md text-sm transition-colors"
                >
                    <Eye className="h-4 w-4" />
                    <span>Previsualizar</span>
                </button>
                <button
                    onClick={onEmit}
                    className="flex items-center space-x-1.5 px-4 py-2 bg-green-600 text-white hover:bg-green-700 rounded-md text-sm transition-colors"
                >
                    <Send className="h-4 w-4" />
                    <span>Emitir</span>
                </button>
            </div>
        </div>
    );
}