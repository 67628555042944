import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { useCountry } from '../context/CountryContext';

const plans = [
    {
        name: 'Starter',
        minDocs: 50,
        maxDocs: 200,
        basePrice: 1,
        basePriceEUR: 25,
        additionalPrice: 0.015,
        additionalPriceEUR: 0.35,
        features: [
            'Emisión rápida',
            'Carga masiva Excel de facturas'
        ],
        isPopular: false,
    },
    {
        name: 'Pro',
        minDocs: 200,
        maxDocs: 500,
        basePrice: 4,
        basePriceEUR: 100,
        additionalPrice: 0.012,
        additionalPriceEUR: 0.30,
        features: [
            'Todo lo anterior',
            'Agente inteligente de facturación',
            'Chat para lectura de PDF de OC',
            'NC masivas carga avanzada'
        ],
        isPopular: true
    },
    {
        name: 'Enterprise',
        minDocs: 500,
        maxDocs: 1000,
        basePrice: 8,
        basePriceEUR: 200,
        additionalPrice: 0.010,
        additionalPriceEUR: 0.25,
        features: [
            'Todo lo anterior',
            'Integración automática con 3 portales B2B'
        ],
    }
];

export function Pricing() {
    const { country, translations } = useCountry();
    const [docCounts, setDocCounts] = useState({
        Starter: 50,
        Pro: 200,
        Enterprise: 500
    });

    const calculateTotalPrice = (plan: typeof plans[0], docCount: number) => {
        const extraDocs = Math.max(0, docCount - plan.minDocs);

        if (country === 'chile') {
            const extraCost = extraDocs * plan.additionalPrice;
            return plan.basePrice + extraCost;
        } else {
            const extraCost = extraDocs * plan.additionalPriceEUR;
            return plan.basePriceEUR + extraCost;
        }
    };

    return (
        <section id="pricing" className="py-16 bg-white">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl md:text-5xl font-bold text-center mb-8">
                    {country === 'chile'
                        ? 'Planes que se adaptan a tu negocio'
                        : 'Planes que se adaptan a tu empresa'}
                </h2>
                <p className="text-xl text-gray-600 text-center mb-4">
                    {country === 'chile'
                        ? 'Elige el plan que mejor se ajuste a tu volumen de facturación'
                        : 'Elige el plan que mejor se ajuste a tu volumen de facturación'}
                </p>
                <p className="text-lg text-green-600 text-center font-medium mb-16">
                    {country === 'chile'
                        ? 'Todos los planes incluyen 14 días de prueba gratis'
                        : 'Todos los planes incluyen 14 días de prueba gratuita'}
                </p>

                <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto items-stretch">
                    {plans.map((plan) => (
                        <div
                            key={plan.name}
                            className={`relative bg-white rounded-2xl shadow-lg overflow-hidden border-2 flex flex-col
                ${plan.isPopular ? 'border-green-500' : 'border-gray-200'}`}
                        >
                            {plan.isPopular && (
                                <div className="absolute top-0 right-0 bg-green-500 text-white px-4 py-1 rounded-bl-lg text-sm font-medium">
                                    {country === 'chile' ? 'Más Popular' : 'Más Popular'}
                                </div>
                            )}

                            <div className="p-8 flex-1 flex flex-col">
                                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>

                                <div className="flex items-baseline mb-6">
                                    <span className="text-4xl font-bold">
                                        {country === 'chile'
                                            ? calculateTotalPrice(plan, docCounts[plan.name as keyof typeof docCounts]).toFixed(1).replace('.', ',')
                                            : calculateTotalPrice(plan, docCounts[plan.name as keyof typeof docCounts]).toFixed(0)}
                                    </span>
                                    <span className="text-xl ml-1">{country === 'chile' ? 'UF' : '€'}</span>
                                    <span className="text-gray-500 ml-2">{country === 'chile' ? '/mes' : '/mes'}</span>
                                </div>

                                <div className="mb-6">
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                {country === 'chile'
                                                    ? `Documentos mensuales: ${docCounts[plan.name as keyof typeof docCounts]}`
                                                    : `Documentos mensuales: ${docCounts[plan.name as keyof typeof docCounts]}`}
                                            </label>
                                            <input
                                                type="range"
                                                min={plan.minDocs}
                                                max={plan.maxDocs}
                                                value={docCounts[plan.name as keyof typeof docCounts]}
                                                onChange={(e) => setDocCounts(prev => ({
                                                    ...prev,
                                                    [plan.name]: parseInt(e.target.value)
                                                }))}
                                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-green-600"
                                            />
                                            <div className="flex justify-between text-xs text-gray-500 mt-1">
                                                <span>{plan.minDocs}</span>
                                                <span>{plan.maxDocs}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 p-3 bg-gray-50 rounded-lg">
                                        <div className="flex justify-between text-sm">
                                            <span className="text-gray-600">{country === 'chile' ? 'Documento adicional:' : 'Documento adicional:'}</span>
                                            <span className="font-medium text-gray-900">
                                                {country === 'chile'
                                                    ? `${plan.additionalPrice.toFixed(3).replace('.', ',')} UF`
                                                    : `${plan.additionalPriceEUR.toFixed(2).replace('.', ',')} €`}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <ul className="space-y-4 mb-8">
                                    {plan.features.map((feature, index) => (
                                        <li key={index} className="flex items-center">
                                            <Check className="h-5 w-5 text-green-500 mr-2" />
                                            <span className="text-gray-600">{feature}</span>
                                        </li>
                                    ))}
                                </ul>

                                <div className="absolute bottom-8 left-0 right-0 px-8">
                                    <a
                                        href="http://localhost:3000/signup"
                                        className={`block w-full py-4 px-8 rounded-lg text-lg font-medium transition-colors text-center
                    ${plan.isPopular
                                                ? 'bg-green-600 text-white hover:bg-green-700'
                                                : 'border-2 border-green-600 text-green-600 hover:bg-green-50'}`}
                                    >
                                        {country === 'chile' ? 'Comenzar' : 'Comenzar'}
                                    </a>
                                </div>
                            </div>
                            {/* Add padding at bottom to account for absolute positioned button */}
                            <div className="h-24"></div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}